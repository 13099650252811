import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Footer = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <>
      <section className="section shadow-sm lazyloaded" id="developer">
        <small>
          <b>Disclaimer:</b>
          We are an authorised marketing partner for this project. Provided
          content is given by respective owners and this website and content is
          for information purpose only and it does not constitute any offer to
          avail for any services. Prices mentioned are subject to change without
          prior notice and properties mentioned are subject to availability. You
          can expect a call, SMS or emails on details registered with us.
          <div className="footer-copyright">
            <span>
              {" "}
              Contact Us at: Highstreet Corporate Center, Kapurbawdi Junction
              Thane (W) 400607{" "}
            </span>
            <span> © Copyright 2024</span>
            <span style={{ marginBottom: "50px" }}>
              <a href="about.html" target="_blank">
                About Us
              </a>{" "}
              |{" "}
              <a href="termsandconditions.html" target="_blank">
                Terms &amp; Conditions
              </a>{" "}
              |{" "}
              <a href="privacy.html" target="_blank">
                Privacy Policy
              </a>{" "}
              |{" "}
              <a href="cookies.html" target="_blank">
                Cookies Policy
              </a>{" "}
            </span>
          </div>
        </small>
      </section>
      <ul className="mob-action nav nav-fill d-lg-none">
        <li
          className="nav-item enqModal"
          onClick={handleShowModal}
          style={{ cursor: "pointer", color: "#fff" }}
        >
          <i className="mi mi-download nav-icon d-inline-block animated slideInDown infinite"></i>{" "}
          Enquire
        </li>
        <a
          className="nav-item enqModal"
          href="https://api.whatsapp.com/send?phone=9136806477&amp;text=Hi,%20I%20Would%20Like%20To%20Know%20More%20About%20Kalpataru%20Heights.%20Please%20Share%20More%20Details!"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <span className="action-icon">
            <img src="images/wh.png" alt="logo" />
          </span>{" "}
          Whatsapp{" "}
        </a>

        <li className="nav-item">
          <a
            href="tel:+919136806477"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            {" "}
            <span className="mi mi-call action-icon"></span> Call Now
          </a>
        </li>
      </ul>
    </>
  );
};

export default Footer;
