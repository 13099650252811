import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Details = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section class="section shadow-sm lazyloaded" id="overview">
      <div class="over1">
        <h1 class="d-block section-heading color-primary text-capitalize">
          Kalpataru Magnus (At Bandra East)
        </h1>

        <p>
          Welcome to Kalpataru Magnus Bandra East, where luxury meets success.
          Designed by renowned architects, Sanjay Puri and Burega Farnell, this
          upscale residential project offers stunning 3, 4 &amp; 4.5 Bed
          residences with a swimming pool, clubhouse, and other luxuries.
          Located in the trendy Bandra East neighborhood, Kalpataru Magnus BKC,
          making it a favorite among urbanites. With upscale restaurants, parks,
          and recreational zones nearby, its the ideal choice for corporate
          professionals. Kalpataru Magnus is the epitome of luxury living in
          Bandra East. <br />
        </p>
      </div>
      <button
        class="chat-btn btn btn-info micro-form-btn effetMoveGradient enqModal download_brochure"
        onClick={handleShowModal}
      >
        <span class="d-inline-block mi mi-download mr-1 animated slideInDown infinite"></span>{" "}
        Download Brochure
      </button>
      <div class="over1">
        <h1 class="d-block section-heading color-primary text-capitalize">
          About Kalpataru Group
        </h1>
        <p>
          Kalpataru Group established in the year 1969, Kalpataru Group is one
          of the leading real estate development companies in India. It has
          successfully developed many landmark projects and has set new
          benchmarks in the real estate industry. The group follows a unique
          approach wherein it focuses on creating residential and commercial
          spaces that offer maximum value to the customers.
        </p>
      </div>
    </section>
  );
};

export default Details;
