import React from "react";

import { useGlobalContext } from "./context/globalContext";

const InfoBox = () => {
  const { handleShowModal } = useGlobalContext();

  return (
    <div>
      <div className="info-box overflow-hidden">
        <span className="pro-status">Bookings Open</span>
        <h1 className="pro-title">kalpataru Magnus</h1>
        <div>
          <h3 className="pro-dev">
            {" "}
            At Bandra East <br />
            By Kalpataru Group
          </h3>
          <h3 className="pro-dev">
            {" "}
            Land Area : 2.5 Acre
            <br />
            Possession : Nearing possession
          </h3>
        </div>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer">
            <span className="offer-text">
              An Exclusive Addition To Bandra Skyline
            </span>
          </span>
        </span>
        <div>
          <h3 className="pro-dev"> Pay 10% Now And Nothing Till Possession*</h3>
        </div>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer" style={{ color: "black" }}>
            <span className="offer-text" style={{ padding: "0px" }}>
              Vaastu Compliant Homes
            </span>
          </span>
        </span>
        <span className="d-block mb-1 text-capitalize of-box offer-bg-animation">
          <span className="offer-text-outer" style={{ color: "black" }}>
            <span className="offer-text" style={{ padding: "0px" }}>
              Serene Views of the Cityscapes
            </span>
          </span>
        </span>
        <span className="d-block"></span>
        <span class="pro-tag-line">
          Luxurious 3, 4 &amp; 4.5 BHK Homes
        </span>{" "}
        <span class="pro-price">
          <span class="strike">₹ 5.59 Cr*</span>

          <small style={{ fontSize: "16px" }}> Onwards.</small>
        </span>
        <button
          className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale"
          onClick={handleShowModal}
        >
          Express Your Interest
        </button>
      </div>
    </div>
  );
};

export default InfoBox;
