import React from "react";
import { useGlobalContext } from "./context/globalContext";

const Carousel = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <section className="section shadow-sm lazyloaded" id="carousel">
      <span className="section-link"></span>{" "}
      <div className="row">
        <div className="col-md-8">
          <h2 className="head text-capitalize">Gallery of kalpataru Magnus</h2>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale float-lg-right mx-sm-auto  d-none d-lg-block"
            onClick={handleShowModal}
          >
            Download Gallery
          </button>
        </div>
      </div>
      <div className="owl-carousel-img owl-carousel owl-theme gallery-img">
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Badminton.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Badminton.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Badminton.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Banquet.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Banquet.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Banquet.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Bedroom.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Bedroom.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Bedroom.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Co-Working-Pods.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Co-Working-Pods.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Co-Working-Pods.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Creche.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Creche.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Creche.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Dance-room.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Dance-room.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Dance-room.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Gym.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Gym.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Gym.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Lawn.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Lawn.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Lawn.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Salon.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Salon.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Salon.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Study-Arena.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Study-Arena.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Study-Arena.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Swimming-pool-view.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Swimming-pool-view.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Swimming-pool-view.webp"
            alt="logo"
          />
        </a>
        <a
          data-fancybox="gallery-0"
          href="images/bhandup/lntevaraheights-Gallery-Yoga.webp"
        >
          {" "}
          <img
            data-src="../images/bhandup/lntevaraheights-Gallery-Yoga.webp"
            loading="lazy"
            className="gallery-thumb ls-is-cached lazyloaded"
            src="images/bhandup/lntevaraheights-Gallery-Yoga.webp"
            alt="logo"
          />
        </a>
      </div>
      <div className="col-12">
        <button
          className="chat-btn btn btn-info micro-form-btn enqModal effetMoveGradient effectScale float-lg-right mx-sm-auto d-lg-none"
          data-form="lg"
          data-title="Download Gallery"
          data-btn="Send Now"
          data-enquiry="Download Gallery"
          data-redirect="floorplan"
          data-toggle="modal"
          data-target="#enqModal"
          onClick={handleShowModal}
        >
          Download Gallery
        </button>
      </div>
    </section>
  );
};

export default Carousel;
