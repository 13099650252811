import React from "react";

import { useGlobalContext } from "./context/globalContext";

const Hero = () => {
  const { handleShowModal } = useGlobalContext();
  return (
    <div id="home" className="section" style={{ padding: "0" }}>
      <div className="desktopview">
        <img
          onClick={handleShowModal}
          height="730"
          width="100%"
          className="d-block micro-main-slider-img ls-is-cached lazyloaded"
          src="images/bhandup/magnus-1.webp"
          alt="Kalpataru"
        />
      </div>
      <div className="mobileview">
        <img
          onClick={handleShowModal}
          height="100%"
          width="100%"
          className="d-block micro-main-slider-img ls-is-cached lazyloaded"
          src="images/bhandup/magnus-1.webp"
          alt="kalpataru"
        />
      </div>
    </div>
  );
};

export default Hero;
